<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="巡更发布" name="patrolRelease"></el-tab-pane>
                <el-tab-pane label="巡更记录" name="patrolRecord"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'patrolRelease'">
            <patrolReleaseTable />
        </div>
        <div v-if="webType === 'patrolRecord'">
            <patrolRecordTable />
        </div>
    </div>
</template>

<script>
import patrolReleaseTable from "./pages/patrolReleaseTable";
import patrolRecordTable from "./pages/patrolRecordTable";
export default {
    name: "patrolDistribution",
    components: {
        patrolReleaseTable,
        patrolRecordTable,
    },
    data() {
        return {
            webType: "patrolRelease",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>